<template>
    <div class="pb-5">
        <SubHeader :title="title" />

        <div class="px-3 mb-2 flex-between-center">
            <span class="small text-gray-relative-500">* 최근 6개월간 문의 내역입니다.</span>
            <router-link to="/cs/inquiry/write" class="btn bg-gray-relative-100 small text-body">문의하기</router-link>
        </div>
        <div class="mx-3">
            <ul class="board_list last-border-0" v-if="inquiry.length>0" >
                <li v-for="(item,index) in inquiry" :key="index" class="border-bottom border-gray-relative-200 ">
                    <div class="txt_box small d-flex w-100 align-items-center py-3 pe-2" @click="open_inquiry_idx == item.idx ? open_inquiry_idx=-1 : open_inquiry_idx = item.no">
                        <div>
                            <p class="tit" :class="{'text-truncate-1' : open_inquiry_idx != item.no}">
                                <pre style="white-space: pre-wrap;">{{item.memo}}</pre>
                            </p>
                            <div class="lh-1 mt-2 text-gray-relative-500 small">
                                <span>{{ChkStatus(item.answer_status)}}</span>
                                <span class="vr mx-2"></span>
                                <span>{{item.date}}</span>
                            </div>
                        </div>
                        <i class="fal small ps-3 ms-auto text-gray-relative-500" :class="{'fa-chevron-down':open_inquiry_idx != item.no, 'fa-chevron-up':open_inquiry_idx == item.no}"></i>
                    </div>
                    <div class="txt_box small px-3 py-4 bg-gray-relative-100 border-top border-gray-relative-200" v-if="open_inquiry_idx == item.no">
                        <div v-if="item.answer != ''">
                            <pre style="white-space: pre-wrap;">{{item.answer}}</pre>
                        </div>
                        <div class="small" v-else>
                            아직 답변이 없습니다.
                        </div>
                    </div>
                </li>
            </ul>
            
            <div class="empty_data" v-else>
                <p class="text-center py-5">
                    문의 내역이 없어요.
                </p>
            </div> 
        </div>
    </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'
const CryptoJS = require("crypto-js");
export default {
    components:{
        SubHeader
    },
    data(){
        return{
            title: '1:1 문의',

            inquiry: [],
            
            open_inquiry_idx: -1,
        }
    },
    mounted(){
        this.GetList();
    },
    methods: {
        GetList(){
            
            this.$http.post('/shop/cs/GetInquiry', {}).then(  
                res => {
                    if(res.status ==200){
                        if(res.data.code=='200'){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);  
                            
                            this.inquiry = body.list
                            
                        }else if(res.data.code=='9999'){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    window.location.href="/signin";
                                }
                            )
                        }
                    }
                }
            )

        },
        ChkStatus(a){
            if( a == 'H' ){
                return '미답변'
            }else{
                return '답변완료'
            }
        }
    },
}
</script>
