<template>
    <div class="pb-4">
        <SubHeader :title="title" />

        <div class="px-3">
            <div>
                <div>
                    <div class="form-group mt-3">
                        <label class="small text-gray-600 ms-1">문의 내용</label>
                        <textarea class="form-control post_desc border rounded p-3 w-100 small bg-gray-relative-0 border-gray-relative-300" rows="10" v-model="desc" maxlength="1000" placeholder="문의 내용을 10자 이상 입력해주세요.">
                        </textarea>
                        <div class="d-flex"><span class="ms-auto small">{{desc.length}}/1000</span></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="px-3 mt-5">
            <button class="btn btn-main thick mb-3 w-100" @click="Submit()" :disabled="desc.length < 10">
                문의하기
            </button>
        </div>
    </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'
const CryptoJS = require("crypto-js");
export default {
    components:{
        SubHeader
    },
    data(){
        return{
            title: '1:1 문의하기',

            email: '',

            tit: '',
            desc: '',

            // front ui
            edit_email_mode: false,
        }
    },
    methods:{
        Submit(){
            
            const memo = this.desc;
            const body = {memo}

            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.$http.post('/shop/cs/AddInquiry', {req}).then(  
                res => {
                    if(res.status ==200){
                        if(res.data.code=='200'){
                            this.$alert("정상적으로 제출하였습니다.").then(
                                ()=>{
                                    this.$router.push(`/cs/inquiry/list`)
                                }
                            )
                            
                            
                        }else if(res.data.code=='9999'){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    window.location.href="/signin";
                                }
                            )
                        }
                    }
                }
            )

        }
    },
    
    mounted(){
    },
}
</script>

<style lang="scss" scoped>
    .post_desc{
        height: calc(100vh - 330px);
        min-height: 250px;
    }
</style>